<template>
  <main>
    <!-- prettier-ignore -->
    <section class="px-2 md:px-0 py-4">
      <div class="container">
        <nav class="breadcrumb" aria-label="breadcrumbs">
          <ul>
            <router-link tag="li" :to="{name: 'home'}"><a>{{$t('breadcrumb_home')}}</a></router-link>
            <router-link class="is-hidden-mobile" tag="li" :to="{name: 'companies'}"><a>{{$t('breadcrumb_companies')}}</a></router-link>
            <router-link tag="li" :to="{ name: 'company', params: { cid } }"><a><company-name :company="company"/></a></router-link>
            <router-link class="is-hidden-mobile" tag="li" :to="{name: 'accounts-org', params: {cid, oid: 'default'}}"><a>{{$t('breadcrumb_accounts')}}</a></router-link>
            <router-link tag="li" :to="{name: 'account', params: {cid, aid}}"><a><account-name :account="account"/></a></router-link>
            <router-link tag="li" :to="{name: 'email', params: {cid, aid}}"><a>{{$t('breadcrumb_email')}}</a></router-link>
          </ul>
        </nav>
      </div>
    </section>
    <sqr-progress v-show="aLoading" />
    <section class="section py-4" v-if="account">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-12-mobile is-6-tablet is-4-desktop">
            <sqr-input-email class="field" label="email_from" v-model="from" />
            <sqr-input-email class="field" label="email_to" v-model="to" />
            <sqr-input-email
              class="field"
              label="email_reply_to"
              v-model="replyTo"
            />
            <sqr-input-email
              class="field"
              label="email_from_given_name"
              v-model="data.fromGivenName"
            />
            <sqr-input-email
              class="field"
              label="email_from_family_name"
              v-model="data.fromFamilyName"
            />
            <sqr-input-email
              class="field"
              label="email_company_name"
              v-model="data.companyName"
            />
            <sqr-input-email
              class="field"
              label="email_password"
              v-model="data.password"
            />
            <sqr-error-note :error="sendError" />
            <div class="buttons is-right">
              <sqr-button
                label="email_send"
                @click="send()"
                color="primary"
                :is-loading="sending"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import account from './account';
import SqrProgress from '@/sqrd/SqrProgress';
import SqrInputEmail from '@/sqrd/SqrInputText';
import SqrErrorNote from '@/sqrd/SqrErrorNote';
import SqrButton from '@/sqrd/SqrButton';

import CompanyName from '@/components/CompanyName';
import AccountName from '@/components/AccountName';

export default {
  name: 'Email',
  mixins: [account],
  components: {
    SqrProgress,
    SqrInputEmail,
    SqrErrorNote,
    SqrButton,
    CompanyName,
    AccountName,
  },
  data() {
    return {
      from: 'SQRD WoTime <info@wotime.ch>',
      to: '',
      replyTo: 'support@wotime.ch',
      data: {
        url: 'https://v3.wotime.ch/',
      },
      sending: false,
      sendError: null,
    };
  },
  watch: {
    account(acc) {
      const { givenName, familyName, email } = acc ?? {};
      this.to = `${givenName} ${familyName} <${email}>`;
    },
  },
  mounted() {
    const { givenName, familyName, email } = this.account ?? {};
    this.to = `${givenName} ${familyName} <${email}>`;
  },
  methods: {
    async send() {
      try {
        this.sending = true;
        this.sendError = null;
        await this.$db()
          .collection('emails')
          .add({
            companyId: this.cid,
            accountId: this.aid,
            from: this.from,
            to: this.to,
            replyTo: this.replyTo,
            template: {
              name: 'welcome-en',
              data: {
                ...this.account,
                ...this.data,
              },
            },
            // message: {
            //   subject: 'Welcome to WoTime! 🎊',
            //   html: 'This is an <code>HTML</code> email body.'
            // }
          });
      } catch (error) {
        this.sendError = error;
      } finally {
        this.sending = false;
      }
    },
  },
};
</script>
